import { render } from 'preact';
import { getConsent, subscribeToConsent } from '@vgno/utils';
import HeiStripe from './HeiStripe.jsx';

const renderWithUnleash = async (props) => {
    const { node, getUnleashClient } = props;

    const unleashClient = await getUnleashClient();
    const isEnabled = unleashClient.isEnabled('hei-stripe-front');

    console.log('Hei VG stripe: Unleash toggle status:', isEnabled);

    if (isEnabled) {
        console.log('Hei VG stripe: Feature toggle enabled, rendering');
        node.style.display = 'block';
        return render(<HeiStripe {...props} />, node);
    }
};

// We export a function that receives props, including "props.node"
export default async function initHeiVg(props) {
    console.log('Hei VG stripe: Initializing');

    // Ensure we have a valid HTMLElement to mount onto
    const { node } = props;
    if (!node || !(node instanceof HTMLElement)) {
        console.error('No valid node found for Hei VG stripe');
        return;
    }

    const consent = await getConsent('CMP:personalisation');
    let initialized = false;

    if (consent) {
        console.log(
            'Hei VG stripe: Consent already given, checking feature toggle',
        );

        return await renderWithUnleash(props);
    } else {
        subscribeToConsent('CMP:personalisation', async (consented) => {
            if (consented && !initialized) {
                initialized = true;
                console.log(
                    'Hei VG stripe: Consent eventually given, checking feature toggle',
                );

                return await renderWithUnleash(props);
            }
        });
    }
}
