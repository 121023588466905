import styles from './HeiStripe.module.css';
import { useState, useEffect } from 'react';
import { trackImpression, trackEngagement } from './tracking.js';

const HeiStripe = ({ node }) => {
    const [inputQuestion, setInputQuestion] = useState('');

    useEffect(() => {
        trackImpression(node);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedQuestion = encodeURIComponent(inputQuestion);

        trackEngagement('Click', {
            contentId: 'submit',
        });

        window.location.href = `https://vg.no/hei?q=${encodedQuestion}&utm_source=front`;
    };

    const handleFocus = () => {
        trackEngagement('Click', {
            contentId: 'focus',
        });
    };

    return (
        <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Spør VGs AI-chat (ikke-redaksjonell tjeneste)"
                    onChange={(e) => {
                        setInputQuestion(e.target.value);
                    }}
                    value={inputQuestion}
                    onFocus={handleFocus}
                    className={styles.input}
                />
                <button
                    type="submit"
                    disabled={!inputQuestion}
                    className={styles.submitButton}
                >
                    {/* ARROW UP ICON */}
                    <svg
                        width="9"
                        height="11"
                        viewBox="0 0 9 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.90915 0.173438C4.80134 0.0632813 4.65603 0 4.50134 0C4.34665 0 4.20134 0.0632813 4.09353 0.173438L0.156028 4.29844C-0.0595974 4.52344 -0.0502224 4.87969 0.174778 5.09297C0.399778 5.30625 0.756028 5.29922 0.969309 5.07422L3.93884 1.96641V9.9375C3.93884 10.2492 4.18962 10.5 4.50134 10.5C4.81306 10.5 5.06384 10.2492 5.06384 9.9375V1.96641L8.03103 5.07656C8.24665 5.30156 8.60056 5.30859 8.82556 5.09531C9.05056 4.88203 9.05759 4.52578 8.84431 4.30078L4.90681 0.175781L4.90915 0.173438Z"
                            fill="var(--button-filled-color)"
                        />
                    </svg>
                </button>
            </form>
        </div>
    );
};

export default HeiStripe;
